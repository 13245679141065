<template>
  <div>
    <!-- 路由内容展示 -->
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    // 页面加载后自动跳转到指定页面
    this.$router.push('/'); // 这里填写要跳转的路径
  }
}
</script>
